.App { /* Base font style */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 16.0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.8500);
  background-color: #f6f6f6;
}

.App .baseFont * { /* Base font style override for UI framework components */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 16.0px;
}

.App .appBg {
  background-color: #f6f6f6;
}

.App .cardBg {
  background-color: white;
}

.App .baseTextBg { /* Base text color as background */
  background-color: rgba(0, 0, 0, 0.8500);
}

.App .secondaryTextColor {
  color: rgba(0, 0, 0, 0.5000);
}

.App .secondaryTextBg {
  background-color: rgba(0, 0, 0, 0.5000);
}

.App .primaryTextColor {
  color: #bee9ee;
}

.App .primaryBg {
  background-color: #bee9ee;
}

.App .highlightTextColor {
  color: #0093d5;
}

.App .highlightBg {
  background-color: #0093d5;
}

@media (display-mode: standalone) {
  .App {
    user-select: none;
  }
}

/* Styles to customize the UI framework plugin (MUI, https://muicss.com) */
.mui-btn {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.mui-btn--primary {
    background-color: #bee9ee;
}
.mui-btn--primary:active, .mui-btn--primary:focus, .mui-btn--primary:hover {
    background-color: #c3ebf0;
}
.mui-btn--accent {
    background-color: #0093d5;
}
.mui-btn--accent:active, .mui-btn--accent:focus, .mui-btn--accent:hover {
    background-color: #069fda;
}
.mui-textfield {  /* Reset UI framework styling that affects layout */
    margin-bottom: 0;
}
.mui-textfield>input, .mui-textfield>textarea {
    font-size: inherit;
}
.mui-checkbox, .mui-radio {  /* Reset UI framework styling that affects layout */
    margin-top: 0;
    margin-bottom: 0;
}
.mui-select {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-btn+.mui-btn {  /* Reset UI framework styling that affects layout */
    margin-left: 0;
}
.mui-btn--fab {  /* Reset UI framework styling that breaks compositing */
    z-index: auto;
}

@media (min-width: 320px) {
  .mui-container {
    max-width: 320px;
  }
}
@media (min-width: 568px) {
  .mui-container {
    max-width: 568px;
  }
}
@media (min-width: 768px) {
  .mui-container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .mui-container {
    max-width: 1024px;
  }
}

@keyframes fadeIn {
    0%   { opacity: 0.0; }
    100% { opacity: 1.0; }
}
@keyframes slideIn_right {
    0%   { transform: translateX(100%); }
    100% { transform: translateX(0); }
}
@keyframes slideIn_bottom {
    0%   { transform: translateY(100%); }
    100% { transform: translateY(0); }
}

/* System font classes */

.App .systemFontRegular {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}
.App .systemFontBold {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
}
.App .systemFontItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-style: italic;
}
.App .systemFontBoldItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
  font-style: italic;
}

/* App-wide text styles specified in React Studio */

.App .actionFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 15.0px;
  font-weight: 400;
}
.App .actionFont * {
  font-size: 15.0px;
}
.App .headlineFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 20.0px;
  font-weight: 600;
}
.App .headlineFont * {
  font-size: 20.0px;
}
.App .navBar {
  background-color: #bee9ee;
  color: black;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  min-height: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 18px;
}

.App .navBar a {
  color: black;
}

.App .navBar .title {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 4px;
}

.App .navBar .backBtn {
  position: absolute;
  padding: 4px;
  margin-left: 14px;
  cursor: pointer;
}

.App .navBar > div {
  margin-top: 7px;
}

.App {
  --tab-bar-height: 54px;
}
@media (display-mode: standalone) {
  .App {
    --tab-bar-height: 90px;
  }
}
.App .tabBar {
  height: var(--tab-bar-height);
  display: flex;
  flex-direction: row;
  pointer-events: auto;
  z-index: 9999;
}
.App .tabBar > * {
  flex: 1 0 auto;
  text-align: center;
  cursor: pointer;
}
.App .tabBar div {
  box-sizing: border-box;
  font-size: 13px; /* default size for tab bar text */
  line-height: 15px;
}
@media (max-width: 767px) {
  .App .tabBar div {
    font-size: 11px; /* small screen size for tab bar text */
    line-height: 13px;
  }
}
.App .tabBar img {
  height: 24px;
  padding-top: 6px;
}
.App .tabBarMenu {
  overflow: hidden;
}
.App .tabBarPageContent {
  overflow: scroll;
  flex: 1;
}
.App .tabBarPageContent > .AppScreen {
  position: relative;
  overflow: scroll;
}
.App .tabBarPageContent > .AppScreen > .background {
  position: fixed;
  left: auto;
  top: auto;
  height: calc(100% - var(--tab-bar-height));
}
.App .tabBarPageContent > .AppScreen > .navBarContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.App .tabBarPageContent > .AppScreen > .navBarContainer > .navBar {
  position: fixed;
  left: auto;
  top: auto;
}
@media (min-width: 568px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 768px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 1024px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
.AppScreen {
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.App .background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App .layoutFlow {
  position: relative;
  width: 100%;
  pointer-events: none;
}
.App .layoutFlow:before {  /* Prevent margin collapse */
  content: "";
  display: table;
  clear: both;
}
.App .layoutFlow .flowRow {
  position: relative;
  width: 100%;
}

.App .screenFgContainer {
  /* Items in the foreground group within a screen are placed with fixed positioning */
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.App .foreground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App ul {
  margin: 0;
  padding: 0;
}
.App li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}
.App .hasNestedComps {
  position: relative;
}
.App .hasNestedComps:after {
  content: "";
  display: table;
  clear: both;
}
.App .hasNestedComps .background {
  position: absolute;
}
.App .hasNestedComps .layoutFlow {
  position: relative;
}
.App .bottomAlignedContent {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.App .blockInputOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: auto;
  z-index: 99999999;
}

.App .marker {
  width: 0px;
  height: 0px;
}

.App .drawer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.App .drawerOpen {
  transform: translateX(0%);
  transition: 0.3s;
}

.App .drawerClosedLeft {
  transform: translateX(-100%);
  transition: 0.3s;
}

.App .drawerClosedRight {
  transform: translateX(100%);
  transition: 0.3s;
}

.App .drawerContent {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

.App .drawerContent > * {
  height: 100%;
}

/* Narrowest size for component's responsive layout */
  .UnlimitedNow {
  }
@media (min-width: 568px) {
  .UnlimitedNow {
  }
}
@media (min-width: 768px) {
  .UnlimitedNow {
  }
}
@media (min-width: 1024px) {
  .UnlimitedNow {
  }
}


/* Narrowest size for this element's responsive layout */
  .UnlimitedNow > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .UnlimitedNow > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .UnlimitedNow > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .UnlimitedNow > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .UnlimitedNow > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 35px;
    width: 215px;
    margin-left: 43px;
  }
  .UnlimitedNow > .layoutFlow > .elText > * { 
  width: 215px;
  }
  
@media (min-width: 568px) {
  .UnlimitedNow > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 34px;
    width: 313px;
    margin-left: 60px;
  }
  .UnlimitedNow > .layoutFlow > .elText > * { 
  width: 313px;
  }
  
}
@media (min-width: 768px) {
  .UnlimitedNow > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 35px;
    width: 584px;
    margin-left: 42px;
  }
  .UnlimitedNow > .layoutFlow > .elText > * { 
  width: 584px;
  }
  
}
@media (min-width: 1024px) {
  .UnlimitedNow > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 16px;
    width: 402px;
    margin-left: 26px;
  }
  .UnlimitedNow > .layoutFlow > .elText > * { 
  width: 402px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .UnlimitedNow > .layoutFlow > .elImage {
    position: relative;
    margin-top: 25px;
    width: 226px;
    margin-left: 37px;
  }
  .UnlimitedNow > .layoutFlow > .elImage > * { 
  width: 226px;
  }
  
@media (min-width: 568px) {
  .UnlimitedNow > .layoutFlow > .elImage {
    position: relative;
    margin-top: -43px;
    width: 316px;
    margin-left: 56px;
  }
  .UnlimitedNow > .layoutFlow > .elImage > * { 
  width: 316px;
  }
  
}
@media (min-width: 768px) {
  .UnlimitedNow > .layoutFlow > .elImage {
    position: relative;
    margin-top: 39px;
    width: 535px;
    margin-left: 43px;
  }
  .UnlimitedNow > .layoutFlow > .elImage > * { 
  width: 535px;
  }
  
}
@media (min-width: 1024px) {
  .UnlimitedNow > .layoutFlow > .elImage {
    position: relative;
    margin-top: 14px;
    width: 405px;
    margin-left: 27px;
  }
  .UnlimitedNow > .layoutFlow > .elImage > * { 
  width: 405px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .UnlimitedNow > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 15px;
    width: 181px;
    margin-left: 33px;
  }
  .UnlimitedNow > .layoutFlow > .elText2 > * { 
  width: 181px;
  }
  
@media (min-width: 568px) {
  .UnlimitedNow > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 19px;
    width: 287px;
    margin-left: 63px;
  }
  .UnlimitedNow > .layoutFlow > .elText2 > * { 
  width: 287px;
  }
  
}
@media (min-width: 768px) {
  .UnlimitedNow > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 24px;
    width: 557px;
    margin-left: 38px;
  }
  .UnlimitedNow > .layoutFlow > .elText2 > * { 
  width: 557px;
  }
  
}
@media (min-width: 1024px) {
  .UnlimitedNow > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 22px;
    width: 419px;
    margin-left: 14px;
  }
  .UnlimitedNow > .layoutFlow > .elText2 > * { 
  width: 419px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Login1Screen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .Login1Screen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .Login1Screen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .Login1Screen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .Login1Screen > .layoutFlow > .elField {
    position: relative;
    margin-top: 185px;
    width: 226px;
    margin-left: 61px;
  }
  .Login1Screen > .layoutFlow > .elField > * { 
  width: 226px;
  }
  
@media (min-width: 568px) {
  .Login1Screen > .layoutFlow > .elField {
    position: relative;
    margin-top: 185px;
    width: 226px;
    margin-left: 61px;
  }
  .Login1Screen > .layoutFlow > .elField > * { 
  width: 226px;
  }
  
}
@media (min-width: 768px) {
  .Login1Screen > .layoutFlow > .elField {
    position: relative;
    margin-top: 184px;
    width: 225px;
    margin-left: 60px;
  }
  .Login1Screen > .layoutFlow > .elField > * { 
  width: 225px;
  }
  
}
@media (min-width: 1024px) {
  .Login1Screen > .layoutFlow > .elField {
    position: relative;
    margin-top: 184px;
    width: 225px;
    margin-left: 60px;
  }
  .Login1Screen > .layoutFlow > .elField > * { 
  width: 225px;
  }
  
}

.Login1Screen .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Login1Screen .elButton button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Login1Screen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 29px;
    width: 145px;
    margin-left: 100px;
  }
  .Login1Screen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .Login1Screen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 29px;
    width: 145px;
    margin-left: 100px;
  }
  .Login1Screen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .Login1Screen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 29px;
    width: 144px;
    margin-left: 100px;
  }
  .Login1Screen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .Login1Screen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 29px;
    width: 144px;
    margin-left: 100px;
  }
  .Login1Screen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .StartScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .StartScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .StartScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .StartScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .StartScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 31px;
    width: 320px;
    margin-left: 49px;
  }
  .StartScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .StartScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 31px;
    width: 320px;
    margin-left: 49px;
  }
  .StartScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .StartScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 29px;
    width: 320px;
    margin-left: -44px;
  }
  .StartScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .StartScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 31px;
    width: 320px;
    margin-left: 48px;
  }
  .StartScreen > .layoutFlow > .elSpacer > * { 
  width: 320px;
  height: 50px;
  }
  
}

.StartScreen > .layoutFlow > .elList > div {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 5px;
  grid-row-gap: 5px;  /* old name still required by some browsers */
  column-gap: 5px;
  grid-column-gap: 5px;  /* old name still required by some browsers */
}

.StartScreen > .layoutFlow > .elList > div > * {
  display: inline-block;
  min-width: 460px;
  position: relative;
}

  @media (min-width: 1024px) {
    .StartScreen > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .StartScreen > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .StartScreen > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .StartScreen > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  


/* Narrowest size for this element's responsive layout */
  .StartScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 66px;
    width: 271px;
    margin-left: 13px;
  }
  .StartScreen > .layoutFlow > .elList > * { 
  width: 271px;
  }
  
@media (min-width: 568px) {
  .StartScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 64px;
    width: 468px;
    margin-left: 25px;
  }
  .StartScreen > .layoutFlow > .elList > * { 
  width: 468px;
  }
  
}
@media (min-width: 768px) {
  .StartScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 66px;
    width: 668px;
    margin-left: 13px;
  }
  .StartScreen > .layoutFlow > .elList > * { 
  width: 668px;
  }
  
}
@media (min-width: 1024px) {
  .StartScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 43px;
    width: 819px;
    margin-left: 10px;
  }
  .StartScreen > .layoutFlow > .elList > * { 
  width: 819px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .StartScreen > .screenFgContainer > .foreground > .elImage {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 661px;
    height: 129px;
  }
@media (min-width: 568px) {
  .StartScreen > .screenFgContainer > .foreground > .elImage {
    left: 0px;
    top: 0px;
    width: 666px;
    height: 105px;
  }
}
@media (min-width: 768px) {
  .StartScreen > .screenFgContainer > .foreground > .elImage {
    left: 0px;
    top: 0px;
    width: 910px;
    height: 106px;
  }
}
@media (min-width: 1024px) {
  .StartScreen > .screenFgContainer > .foreground > .elImage {
    left: 0px;
    top: 0px;
    width: 1426px;
    height: 65px;
  }
}

.StartScreen .elButton2 {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.StartScreen .elButton2:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .StartScreen > .screenFgContainer > .foreground > .elButton2 {
    line-height: 35px;
    position: absolute;
    left: 113px;
    top: 41px;
    width: 178px;
    height: 35px;
  }
@media (min-width: 568px) {
  .StartScreen > .screenFgContainer > .foreground > .elButton2 {
    line-height: 35px;
    left: 392px;
    top: 40px;
    width: 178px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .StartScreen > .screenFgContainer > .foreground > .elButton2 {
    line-height: 35px;
    left: 574px;
    top: 38px;
    width: 177px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .StartScreen > .screenFgContainer > .foreground > .elButton2 {
    line-height: 35px;
    left: 1245px;
    top: 24px;
    width: 177px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .StartScreen > .screenFgContainer > .foreground > .elText {
    overflow: hidden;
    position: absolute;
    left: 175px;
    top: 95px;
    width: 49px;
    height: 22px;
  }
@media (min-width: 568px) {
  .StartScreen > .screenFgContainer > .foreground > .elText {
    overflow: hidden;
    left: 34px;
    top: 34px;
    width: 62px;
    height: 37px;
  }
}
@media (min-width: 768px) {
  .StartScreen > .screenFgContainer > .foreground > .elText {
    overflow: hidden;
    left: 57px;
    top: 53px;
    width: 49px;
    height: 22px;
  }
}
@media (min-width: 1024px) {
  .StartScreen > .screenFgContainer > .foreground > .elText {
    overflow: hidden;
    left: 1161px;
    top: 30px;
    width: 55px;
    height: 26px;
  }
}

.StartScreen .elFab {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.StartScreen .elFab:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .StartScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    position: absolute;
    left: 264px;
    top: 673px;
    width: 68px;
    height: 68px;
  }
@media (min-width: 568px) {
  .StartScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    left: 624px;
    top: 684px;
    width: 68px;
    height: 68px;
  }
}
@media (min-width: 768px) {
  .StartScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    left: 633px;
    top: 912px;
    width: 68px;
    height: 68px;
  }
}
@media (min-width: 1024px) {
  .StartScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    left: 1306px;
    top: 678px;
    width: 63px;
    height: 68px;
  }
}

